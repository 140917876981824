import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Paypal from "../components/paypal"

import Container from "../components/container"

import "./pricingtable.css"

const PricingTable = () => {

    const data = useStaticQuery(
        graphql`
          query {
            contentfulSection(section: {eq: "pricingtable"}) {
                subtitle
                title
                bodyText {
                    raw
                }
            }
            allContentfulPricing(sort: {fields: contentfulid, order: ASC}) {
                nodes {
                  id
                  price
                  description
                  list {
                    raw
                  }
                  plan_id
                }
            }
          }
        `
    )

    /*
    const product = {
        description: "test description",
        price: 19,
        plan_id: "P-33N6918310525270CMLWL4DY"
    }
    */

    const Text = ({ children }) => <p>{children}</p>

    const options = {
        renderNode: {
            [BLOCKS.LIST_ITEM]: (node, children) => <li><Icon icon={checkmarkCircled} /> {node.content[0].content[0].value}</li>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
    }

    return (
        <Container id="pricingtable">
            <div className="defaultWrapper">
                <div className="pricingtableWrapper">
                    <h1>{data.contentfulSection.title}</h1>
                    <h2>{data.contentfulSection.subtitle}</h2>
                    <div className="pricingtableBlock">
                        {data.allContentfulPricing.nodes.map(node => {
                            return (
                                <div className="pricingtableItem" key={node.id}>
                                    <div className="pricingtableTitle" >
                                        <h3>{node.price}</h3>
                                        <p>{node.description}</p>
                                    </div>
                                    <div className="pricingtableList" >
                                        {renderRichText(node.list, options)}
                                    </div>
                                    <div className="pricingtableFooter" >
                                        { /*
                                        <a href={node.link} target="_blank" rel="noreferrer">
                                            <button className="pricingtableButton">Buy Now</button>
                                        </a>
                            */ }
                                        <div className={"paypal-button-container-" + node.plan_id}>
                                            <Paypal plan_id={node.plan_id} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="pricingtableTerms">
                        {renderRichText(data.contentfulSection.bodyText, options)}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PricingTable