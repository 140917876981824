import * as React from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"

import PricingTable from '../containers/pricingtable';

import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Adfree = () => {

  /*const data = useStaticQuery(
    /*graphql`
      query {
        contentfulAdfree(contentfulid: {eq: 1}) {
          content {
            raw
          }
        }
      }
    `
  )*/

  //const Text = ({ children }) => <p>{children}</p>

  /*const options = {
      renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
  }*/

  return (

    <PayPalScriptProvider
      options={{
        "client-id": process.env.GATSBY_PAYPAL_LIVE_CLIENT_ID,
        currency: "AUD",
        intent: "subscription",
        vault: true
      }}>
      <Layout page="internal">
        <Seo title="Go Ad-Free" />
        <Container className="container">
          <PricingTable />
        </Container>
      </Layout>
    </PayPalScriptProvider>
  )

}

export default Adfree