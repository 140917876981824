import React from "react"
import { navigate } from "gatsby"
import { useState } from "react"
import { PayPalButtons } from "@paypal/react-paypal-js";

const Paypal = (props) => {
    const { plan_id } = props;

    //console.log(plan_id);

    const [paidFor, setPaidFor] = useState(false);
    const [error, setError] = useState(null);
    const [subscriptionID, setSubscriptionID] = useState(null);

    const handleApprove = (subscriptionID) => {
        // Call backend function to fulfill order
        console.log("subscriptionID = ", subscriptionID);

        // if response is success
        setPaidFor(true);
        setSubscriptionID(subscriptionID);
        // Refresh user's account or subscription status

        // if the response is error
        // setError("Your payment was processed successfully. However we have encountered an error, please contact us at shop@nearkey.co for assistance")
    };

    if (paidFor) {
        // Display a success message, modal or redirect user to success page
        // alert("Thank you for your purchase!");
        navigate("/order-success/",
            {
                state: { subscriptionID: subscriptionID },
            });
    }

    if (error) {
        // Display error message, modal or redirect user to error page
        alert(error);
    }

    return <PayPalButtons
        style={{
            color: "gold",
            layout: "horizontal",
            shape: "rect"
        }}
        onClick={(data, actions) => {
            // Validate on button click, client or server side
            const hasAlreadyBought = false;

            if (hasAlreadyBought) {
                setError("You are already on a subscription plan");
                return actions.reject()
            } else {
                return actions.resolve()
            }
        }}
        /*createOrder={(data, actions) => {
            return actions.order.create({
                purchase_units: [
                    {
                        description: product.description,
                        amount: {
                            value: product.price
                        }
                    }
                ]
            });
        }}*/
        createSubscription={(data, actions) => {
            return actions.subscription.create({
                plan_id: plan_id
            })
        }}
        onApprove={async (data, actions) => {
            //console.log("subscription info = ", data);
            handleApprove(data.subscriptionID);
        }}
        /*onApprove={async(data, actions) => {
            /*
            const order = await actions.order.capture();
            console.log("order", order)

            handleApprove(data.orderID);
            
            //const order = await actions.order.capture();
            //console.log("order", order)

            handleApprove(data.subscriptionID);
        }}*/
        onCancel={() => {
            // Display cancel message, modal or redirect the user to cancel page or back to cart
        }}
        onError={(err) => {
            setError(err);
            console.error("Paypal Checkout onError", err);
        }}
    />;
};

export default Paypal;